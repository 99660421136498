<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.setup.title') }}</Heading>

    <ol class="tw-list-decimal tw-pl-6">
      <li class="tw-mb-5 tw-space-y-4">
        <Text>
          <i18n-t keypath="settings_modal.setup.load_default_message" scope="global">
            <template #default_settings>
              <strong class="tw-font-black">{{ $t('settings_modal.setup.strong.default_settings') }}</strong>
            </template>

            <template #main_camera_link>
              <Link :href="app.main_cam_url" scheme="underline" target="_blank">{{
                $t('settings_modal.setup.main_camera')
              }}</Link>
            </template>
          </i18n-t>
        </Text>

        <div class="tw--ml-6 tw-flex tw-flex-wrap tw-items-start tw-gap-2">
          <Button
            color="semiTransparentWhite"
            :disabled="locked || disabled"
            :loading="resetNateSettingsInProgress"
            @click="resetNateSettings"
          >
            {{ $t('settings_modal.setup.load_default_nate') }}

            <div
              v-if="resetNateSettingsSucess && !resetNateSettingsInProgress"
              class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-lg tw-backdrop-blur-sm tw-backdrop-filter"
            >
              <Icon name="check" class="tw-size-6 tw-fill-state-succ" />
            </div>

            <div
              v-if="resetNateSettingsError && !resetNateSettingsInProgress"
              class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-lg tw-backdrop-blur-sm tw-backdrop-filter"
            >
              <Icon name="close" class="tw-size-6 tw-fill-state-err" />
            </div>
          </Button>

          <Button
            color="semiTransparentWhite"
            :disabled="locked || disabled"
            :loading="resetMainCamSettingsInProgress"
            class="tw-text-left"
            @click="resetMainCamSettings"
          >
            {{ $t('settings_modal.setup.load_default_camera') }}

            <div
              v-if="resetMainCamSettingsSuccess && !resetMainCamSettingsInProgress"
              class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-lg tw-backdrop-blur-sm tw-backdrop-filter"
            >
              <Icon name="check" class="tw-size-6 tw-fill-state-succ" />
            </div>

            <div
              v-if="resetMainCamSettingsError && !resetMainCamSettingsInProgress"
              class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-lg tw-backdrop-blur-sm tw-backdrop-filter"
            >
              <Icon name="close" class="tw-size-6 tw-fill-state-err" />
            </div>
          </Button>
        </div>
      </li>

      <li class="tw-mb-5 tw-space-y-4">
        <Text>
          <i18n-t keypath="settings_modal.setup.track_name_message" scope="global">
            <template #track_name>
              <strong class="tw-font-black">{{ $t('settings_modal.setup.strong.track_name') }}</strong>
            </template>
          </i18n-t>
        </Text>

        <div class="tw--ml-6 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
          <FormField
            v-model="trackName"
            :disabled="locked || disabled"
            :maxlength="25"
            :label="$t('settings_modal.setup.track_name.label')"
          />
        </div>
      </li>

      <li class="tw-mb-5 tw-space-y-4 last:tw-mb-0">
        <Text>
          <i18n-t keypath="settings_modal.setup.vmix_message" scope="global">
            <template #vmix_ip>
              <strong class="tw-font-black">{{ $t('settings_modal.setup.strong.vmix_ip') }}</strong>
            </template>

            <template #labels>
              <strong class="tw-font-black">{{ $t('settings_modal.setup.strong.labels') }}</strong>
            </template>
          </i18n-t>
        </Text>

        <div class="tw--ml-6 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
          <FormField
            v-model="vMixIpPort"
            :mask="vMixIpPortMask"
            :disabled="locked"
            :label="$t('settings_modal.setup.vmix_ip_port.label')"
          />

          <FormField
            v-model="vMixMainCamLabel"
            :disabled="locked || disabled"
            :label="$t('settings_modal.setup.cam1_label.label')"
          />

          <FormField
            v-model="vMixWideCamLabel"
            :disabled="locked || disabled"
            :label="$t('settings_modal.setup.wideshot_cam_label.label')"
          />

          <FormField
            v-model="vMixNateCamLabel"
            :disabled="locked || disabled"
            :label="$t('settings_modal.setup.nate_cam_label.label')"
          />
        </div>
      </li>

      <template v-if="showAllSetupSteps">
        <li class="tw-space-y-4">
          <Text>
            <span v-html="$t('settings_modal.setup.camera_position_message')"></span>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <i18n-t keypath="settings_modal.setup.exposure_white_balance_message" scope="global">
              <template #main_camera_link>
                <Link :href="app.main_cam_url" scheme="underline" target="_blank">{{
                  $t('settings_modal.setup.main_camera')
                }}</Link>
              </template>

              <template #wideshot_camera_link>
                <Link :href="app.wide_cam_url" scheme="underline" target="_blank">{{
                  $t('settings_modal.setup.wideshot_camera')
                }}</Link>
              </template>
            </i18n-t>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <i18n-t keypath="settings_modal.setup.main_audio_message" scope="global">
              <template #main_camera_link>
                <Link :href="app.main_cam_url" scheme="underline" target="_blank">{{
                  $t('settings_modal.setup.main_camera')
                }}</Link>
              </template>
            </i18n-t>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <i18n-t keypath="settings_modal.setup.wideshot_audio_message" scope="global">
              <template #wideshot_camera_link>
                <Link :href="app.wide_cam_url" scheme="underline" target="_blank">{{
                  $t('settings_modal.setup.wideshot_camera')
                }}</Link>
              </template>
            </i18n-t>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <span v-html="$t('settings_modal.setup.tracking_zone_message')"></span>
          </Text>
        </li>

        <li class="tw-space-y-4">
          <Text>
            <span v-html="$t('settings_modal.setup.tracker_mode_message')"></span>
          </Text>
        </li>
      </template>
    </ol>

    <Button color="transparentWhite" @click="toggleSetupSteps">
      <template v-if="!showAllSetupSteps">
        {{ $t('settings_modal.setup.show_more_steps') }}
      </template>

      <template v-else>
        {{ $t('settings_modal.setup.show_less_steps') }}
      </template>

      <Icon
        name="chevron-down"
        class="tw-relative tw-top-px tw-ml-2 tw-size-6 tw-fill-current tw-transition-transform"
        :class="{ 'tw-rotate-180': showAllSetupSteps }"
      />
    </Button>
  </div>
</template>

<script setup>
import api from '@/api';
import useLoadingUntilRequested from '@/composables/loading_until_requested';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, FormField, Heading, Icon, Link, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['actionInProgress', 'settingsReset']);

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { app } = storeToRefs(dataStore);

const trackName = defineModel('trackName');
const vMixIpPort = defineModel('vMixIpPort');
const vMixMainCamLabel = defineModel('vMixMainCamLabel');
const vMixWideCamLabel = defineModel('vMixWideCamLabel');
const vMixNateCamLabel = defineModel('vMixNateCamLabel');

const resetNateSettingsSucess = ref(false);
const resetNateSettingsError = ref(false);
const resetMainCamSettingsSuccess = ref(false);
const resetMainCamSettingsError = ref(false);
const showAllSetupSteps = ref(false);
const vMixIpPortMask = computed(() => ({
  mask: '#00.#00.#00.#00:00000',
  tokens: {
    0: { pattern: /[0-9]/, optional: true },
  },
}));

const toggleSetupSteps = () => {
  showAllSetupSteps.value = !showAllSetupSteps.value;
};

const showResetNateSettingsResult = (success) => {
  resetNateSettingsSucess.value = success;
  resetNateSettingsError.value = !success;

  setTimeout(() => {
    resetNateSettingsSucess.value = false;
    resetNateSettingsError.value = false;
  }, 3000);
};

const { loading: resetNateSettingsInProgress, trigger: resetNateSettings } = useLoadingUntilRequested(
  api.resetNateSettings,
  {
    successCallback: () => {
      showResetNateSettingsResult(true);
      emit('settingsReset');
    },
    errorCallback: () => {
      showResetNateSettingsResult(false);
    },
  },
);

watch(resetNateSettingsInProgress, (inProgress) => {
  emit('actionInProgress', inProgress);
});

const showResetMainCamSettingsResult = (success) => {
  resetMainCamSettingsSuccess.value = success;
  resetMainCamSettingsError.value = !success;

  setTimeout(() => {
    resetMainCamSettingsSuccess.value = false;
    resetMainCamSettingsError.value = false;
  }, 3000);
};

const { loading: resetMainCamSettingsInProgress, trigger: resetMainCamSettings } = useLoadingUntilRequested(
  api.resetMainCamSettings,
  {
    successCallback: () => {
      showResetMainCamSettingsResult(true);
      emit('settingsReset');
    },
    errorCallback: () => {
      showResetMainCamSettingsResult(false);
    },
  },
);

watch(resetMainCamSettingsInProgress, (inProgress) => {
  emit('actionInProgress', inProgress);
});
</script>
