import useNotificationsStore from '@/stores/notifications';
import handleError from '@/utils/handle_request_error';
import handleResponse from '@/utils/handle_request_response';
import { generateRandomId, isArray } from '@slideslive/fuse-kit/utils';
import { readonly, ref, unref } from 'vue';

/**
 * Composable options
 * @typedef {Object} Options
 * @property {boolean} [disabled=false] Whether sending requests is disabled or not.
 * @property {boolean} [withoutData=false] Whether to send the API request without date or not.
 * For example for DELETE or POST requests without bodies.
 * @property {boolean} [doNotAbortWhenLoading=false] Whether to abort previous request or not.
 * @property {Function} [successCallback=null] Callback to call when request is successful.
 * @property {Function} [errorCallback=null] Callback to call when request fails.
 */

/**
 * Composable used for performing API requests
 * Provides loading state, abort functionality and request callbacks.
 * `loading` is set to false when request is finished.
 * `trigger` should be used to trigger the API request.
 *
 * @param {Function} queryFn Function called by the composable to perform the API request.
 * @param {Options=} options Composable options
 * @return {{ loading, trigger }} `loading` is a reactive loading state of the API request.
 * `trigger` is a function used to trigger the API request.
 */
function useLoadingRequest(
  queryFn,
  {
    disabled = false,
    withoutData = false,
    doNotAbortWhenLoading = false,
    successCallback = null,
    errorCallback = null,
  } = {},
) {
  const notificationsStore = useNotificationsStore();
  const { closeNotification } = notificationsStore;

  const queryId = generateRandomId(20);
  const loading = ref(false);
  let lastRequestId = null;
  let abortController = new AbortController();

  /**
   * Function to trigger the queryFn
   *
   * @param {*} data - Data to be sent to the server and optionaly draftState.
   * @param {{ key?, [key]? }} options - Options to be passed to the queryFn, usually contain 'key' and '[key]' for
   * replacing item ID ({ key: 'speaker_id', speakerId: 123 }).
   */
  const trigger = async (...args) => {
    if (unref(disabled)) return;

    if (unref(loading) && !unref(doNotAbortWhenLoading)) {
      abortController.abort();
    }

    if (abortController.signal.aborted) {
      abortController = new AbortController();
    }

    loading.value = true;

    const requestId = generateRandomId(20);
    let data;
    let params;

    if (withoutData) {
      [params] = args;
    } else {
      [data, params] = args;
    }

    lastRequestId = requestId;

    try {
      let response;

      closeNotification(queryId);

      if (withoutData) {
        response = await unref(queryFn)(null, { ...params, abortController });
      } else {
        response = await unref(queryFn)(data, { ...params, abortController });
      }

      if (isArray(response)) {
        response = response.filter((r) => r);
      }

      if (!response || (isArray(response) && !response.length)) return;

      const success = handleResponse(response);

      if (success && successCallback) {
        successCallback(response);
      } else if (!success && errorCallback) {
        errorCallback(response, null);
      }
    } catch (error) {
      handleError(error, params, data, queryId);

      if (errorCallback) {
        errorCallback(null, error);
      }
    } finally {
      if (lastRequestId === requestId) {
        loading.value = false;
      }
    }
  };

  return {
    loading: readonly(loading),
    trigger,
  };
}

export default useLoadingRequest;
