<template>
  <div ref="root" class="tw-flex tw-gap-x-5">
    <div class="tw-relative tw-select-none [[style]>&]:tw-flex-1">
      <img v-show="!disabledPreviewAndMultiview" :src="previewSrc" class="tw-size-full tw-object-cover" />

      <label v-if="previewActionDisabled" class="tw-z-20">
        <Icon name="lock" class="tw-absolute tw-right-2 tw-top-2 tw-size-6 tw-fill-white/89" />
      </label>

      <PreviewActions v-if="!locked && !previewActionDisabled" />
    </div>

    <div
      :class="[
        'tw-hidden tw-flex-col tw-items-center tw-gap-2 sm:tw-flex',
        controller.control_mode === 'tracker' && 'tw-invisible',
      ]"
    >
      <Range
        v-model.number="mainCameraZoom"
        :disabled="locked || previewActionDisabled"
        :min="cameras.main.zoom.min"
        :max="cameras.main.zoom.max"
        :step="cameras.main.zoom.step"
        vertical
        hide-buttons
        hide-axis-labels
        class="tw-flex tw-flex-1 tw-items-stretch"
        @update:model-value="throttledSetZoom"
      />

      <CircularButton
        :disabled="locked || previewActionDisabled"
        ref="zoomIn"
        icon="zoom-in"
        color="semiTransparentWhite"
        size="small"
        class="tw-shrink-0 tw-select-none"
        :icon-attrs="{ class: 'tw-size-5' }"
      />

      <CircularButton
        :disabled="locked || previewActionDisabled"
        ref="zoomOut"
        icon="zoom-out"
        color="semiTransparentWhite"
        size="small"
        class="tw-shrink-0 tw-select-none"
        :icon-attrs="{ class: 'tw-size-5' }"
      />
    </div>
  </div>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import useSliderValue from '@/composables/slider_value';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import buildNateUrl from '@/utils/build_nate_url';
import { CircularButton, Icon, Range, useHoldButton } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, readonly, ref, useTemplateRef, watch } from 'vue';

import PreviewActions from './previewActions/PreviewActions.vue';

const appStore = useAppStore();
const { locked, previewActionDisabled, disabledPreviewAndMultiview } = storeToRefs(appStore);
const dataStore = useDataStore();
const { cameras, controller, sessionId } = storeToRefs(dataStore);

const rootRef = useTemplateRef('root');
const zoomInRef = useTemplateRef('zoomIn');
const zoomOutRef = useTemplateRef('zoomOut');

const mainCameraZoom = ref(cameras.value.main.zoom.value);
const srcParamToReload = ref(0);

const previewSrc = computed(() =>
  disabledPreviewAndMultiview.value ? null : buildNateUrl(`/streams/preview?nate_session_id=${sessionId.value}`),
);

const { trigger: setZoom } = useLoadingRequest(api.setZoom);

const { throttledQueryFn: throttledSetZoom } = useSliderValue({
  queryFn: () => setZoom({ zoom: mainCameraZoom.value }),
  resetToStateFn: () => {
    mainCameraZoom.value = cameras.value.main.zoom.value;
  },
  stateValueToWatch: () => cameras.value.main.zoom.value,
});

useHoldButton(zoomInRef, () => {
  if (mainCameraZoom.value === cameras.value.main.zoom.max) return;

  mainCameraZoom.value = Math.min(cameras.value.main.zoom.max, mainCameraZoom.value + cameras.value.main.zoom.step);

  throttledSetZoom();
});

useHoldButton(zoomOutRef, () => {
  if (mainCameraZoom.value === cameras.value.main.zoom.min) return;

  mainCameraZoom.value = Math.max(cameras.value.main.zoom.min, mainCameraZoom.value - cameras.value.main.zoom.step);

  throttledSetZoom();
});

watch(disabledPreviewAndMultiview, (value) => {
  if (value) {
    srcParamToReload.value += 1;
  }
});

defineExpose({
  el: readonly(rootRef),
});
</script>
