<template>
  <Teleport to="#teleport-container">
    <Modal ref="modal" v-model="show" :persistent="saving" @opening="reset" @close="reset">
      <ModalContent size="xlarge">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('settings_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button :disabled="actionInProgress" color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
          <Button :loading="actionInProgress" type="submit" form="settings-form">{{ $t('save') }}</Button>
        </template>

        <Form id="settings-form" class="tw-space-y-5" @submit="triggerSave">
          <Setup
            v-model:trackName="trackName"
            v-model:vMixIpPort="vMixIpPort"
            v-model:vMixMainCamLabel="vMixMainCamLabel"
            v-model:vMixWideCamLabel="vMixWideCamLabel"
            v-model:vMixNateCamLabel="vMixNateCamLabel"
            :disabled="actionInProgress"
            @action-in-progress="setSetupActionInProgress"
            @settings-reset="reset"
          />

          <Preview
            ref="preview"
            v-model:fps="fps"
            v-model:encoderQuality="encoderQuality"
            v-model:encoderType="encoderType"
            :disabled="actionInProgress"
          />

          <Personal
            v-model:tooltips="tooltips"
            v-model:previewInfo="previewInfo"
            v-model:resourcesUsage="resourcesUsage"
            :disabled="actionInProgress"
          />

          <Other :disabled="actionInProgress" @action-in-progress="setSetupActionInProgress" />
        </Form>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingUntilRequested from '@/composables/loading_until_requested';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import setIfChanged from '@/utils/set_if_changed';
import { capitalizeFirstLetter } from '@slideslive/fuse-kit/utils';
import { Button, Form, Heading, Modal, ModalContent } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, unref, useTemplateRef, watch } from 'vue';

import Other from './Other.vue';
import Personal from './Personal.vue';
import Preview from './Preview.vue';
import Setup from './Setup.vue';

const appStore = useAppStore();
const { locked, tooltips: tooltipsStore, resourcesUsage: resourcesUsageStore } = storeToRefs(appStore);
const dataStore = useDataStore();
const { app, controller, switcher } = storeToRefs(dataStore);

const show = defineModel();
const modalRef = useTemplateRef('modal');
const previewRef = useTemplateRef('preview');

const trackName = ref(app.value.track_name);
const vMixIpPort = ref(switcher.value.vmix_ip_port);
const vMixMainCamLabel = ref(switcher.value.vmix_main_cam_label);
const vMixWideCamLabel = ref(switcher.value.vmix_wide_cam_label);
const vMixNateCamLabel = ref(switcher.value.vmix_nate_cam_label);
const fps = ref(controller.value.output_gui_video_fps.value);
const encoderQuality = ref(controller.value.output_gui_video_quality.value);
const encoderType = ref(controller.value.output_gui_video_encoder.value);
const tooltips = ref(tooltipsStore.value);
const previewInfo = ref(controller.value.show_preview_info);
const resourcesUsage = ref(resourcesUsageStore.value);
const setupActionInProgress = ref(false);

const { trigger: patchStateApp } = useDraftState(api.patchState, { statePathToClear: ['app'] });
const { trigger: patchStateController } = useDraftState(api.patchState, { statePathToClear: ['controller'] });
const { trigger: patchSwitcher } = useDraftState(api.patchSwitcher, { statePath: ['switcher'] });
const { trigger: setShowPreviewInfo } = useDraftState(api.setShowPreviewInfo, {
  statePath: ['controller', 'show_preview_info'],
  draftStateValue: previewInfo,
});

const setSetupActionInProgress = (inProgress) => {
  setupActionInProgress.value = inProgress;
};

const save = async () => {
  const stateAppPatch = {};
  const stateControllerPatch = {};
  const switcherPatch = {};
  const settingsPatch = {};

  setIfChanged(settingsPatch, 'tooltips', tooltipsStore, tooltips);
  setIfChanged(settingsPatch, 'resourcesUsage', resourcesUsageStore, resourcesUsage);

  for (const key of Object.keys(settingsPatch)) {
    appStore[`set${capitalizeFirstLetter(key)}`](unref(settingsPatch[key]));
  }

  if (locked.value) return Promise.resolve();

  setIfChanged(stateAppPatch, 'track_name', app.value.track_name, trackName);
  setIfChanged(stateControllerPatch, 'output_gui_video_fps', controller.value.output_gui_video_fps.value, {
    value: fps.value,
  });
  setIfChanged(stateControllerPatch, 'output_gui_video_quality', controller.value.output_gui_video_quality.value, {
    value: encoderQuality.value,
  });
  setIfChanged(stateControllerPatch, 'output_gui_video_encoder', controller.value.output_gui_video_encoder.value, {
    value: encoderType.value,
  });

  setIfChanged(switcherPatch, 'vmix_ip_port', switcher.value.vmix_ip_port, vMixIpPort);
  setIfChanged(switcherPatch, 'vmix_main_cam_label', switcher.value.vmix_main_cam_label, vMixMainCamLabel);
  setIfChanged(switcherPatch, 'vmix_wide_cam_label', switcher.value.vmix_wide_cam_label, vMixWideCamLabel);
  setIfChanged(switcherPatch, 'vmix_nate_cam_label', switcher.value.vmix_nate_cam_label, vMixNateCamLabel);

  const promises = [];

  if (Object.keys(stateAppPatch).length) {
    promises.push(patchStateApp({ app: stateAppPatch }));
  }

  if (Object.keys(stateControllerPatch).length) {
    promises.push(patchStateController({ controller: stateControllerPatch }));
  }

  if (previewInfo.value !== controller.value.show_preview_info) {
    promises.push(setShowPreviewInfo({ show_preview_info: previewInfo.value }));
  }

  if (Object.keys(switcherPatch).length) {
    promises.push(patchSwitcher(switcherPatch));
  }

  if (promises.length === 0) return Promise.resolve();

  return Promise.all(promises);
};

const reset = () => {
  trackName.value = app.value.track_name;
  vMixIpPort.value = switcher.value.vmix_ip_port;
  vMixMainCamLabel.value = switcher.value.vmix_main_cam_label;
  vMixWideCamLabel.value = switcher.value.vmix_wide_cam_label;
  vMixNateCamLabel.value = switcher.value.vmix_nate_cam_label;
  fps.value = controller.value.output_gui_video_fps.value;
  encoderQuality.value = controller.value.output_gui_video_quality.value;
  encoderType.value = controller.value.output_gui_video_encoder.value;
  tooltips.value = tooltipsStore.value;
  previewInfo.value = controller.value.show_preview_info;
  resourcesUsage.value = resourcesUsageStore.value;

  previewRef.value.resetPreviewQuality();
};

const { loading: saving, trigger: triggerSave } = useLoadingUntilRequested(save);

const actionInProgress = computed(() => saving.value || setupActionInProgress.value);

watch(saving, (newSaving, oldSaving) => {
  if (oldSaving && !newSaving) {
    modalRef.value.accept();
  }
});
</script>
