import fetcher from './fetcher';

const api = {
  open: fetcher.get.bind(fetcher, '/open'),

  changelog: fetcher.get.bind(fetcher, '/files/changelog'),

  state: fetcher.get.bind(fetcher, '/state'),
  patchState: fetcher.patch.bind(fetcher, '/state'),

  patchDev: fetcher.patch.bind(fetcher, '/dev'),

  resetNateSettings: fetcher.post.bind(fetcher, '/settings/-/reset_nate_settings'),
  resetMainCamSettings: fetcher.post.bind(fetcher, '/settings/-/reset_main_camera_settings'),
  resetCamPlatform: fetcher.post.bind(fetcher, '/settings/-/camera_platform_reset'),
  resetToHomeView: fetcher.post.bind(fetcher, '/settings/-/reset_to_home_view'),
  setAsHomeView: fetcher.post.bind(fetcher, '/settings/-/set_as_home_view'),
  setAutoSpeakerSelection: fetcher.post.bind(fetcher, '/settings/-/auto_speaker_selection'),
  setAutoSpeakerRemoval: fetcher.post.bind(fetcher, '/settings/-/auto_speaker_removal'),
  setShowPreviewInfo: fetcher.post.bind(fetcher, '/settings/-/show_preview_info'),
  setWatcherMode: fetcher.post.bind(fetcher, '/settings/-/set_watcher_mode'),
  setTrackerMode: fetcher.post.bind(fetcher, '/settings/-/set_tracker_mode'),

  patchSwitcher: fetcher.patch.bind(fetcher, '/switcher'),
  setAutoCut: fetcher.post.bind(fetcher, '/switcher/-/auto_cut'),
  setCamera: fetcher.post.bind(fetcher, '/switcher/-/switch_cam'),

  patchMainCamera: fetcher.patch.bind(fetcher, '/cameras/main/audio'),

  moveCamera: fetcher.patch.bind(fetcher, '/move'),
  moveCameraInSteps: fetcher.patch.bind(fetcher, '/move_step'),
  moveToPoint: fetcher.post.bind(fetcher, '/move/to_point'),
  moveToSpeaker: fetcher.post.bind(fetcher, '/move/to_speaker'),
  loadPreset: fetcher.post.bind(fetcher, '/move/to_preset'),
  setZoom: fetcher.post.bind(fetcher, '/move/-/set_zoom'),

  realignComposition: fetcher.post.bind(fetcher, '/composition/-/realign'),
  resetComposition: fetcher.post.bind(fetcher, '/composition/-/reset'),
  moveCompositionLeft: fetcher.post.bind(fetcher, '/composition/-/step_left'),
  moveCompositionRight: fetcher.post.bind(fetcher, '/composition/-/step_right'),
  moveCompositionUp: fetcher.post.bind(fetcher, '/composition/-/step_up'),
  moveCompositionDown: fetcher.post.bind(fetcher, '/composition/-/step_down'),

  setFocusFar: fetcher.post.bind(fetcher, '/focus/-/far'),
  setFocusNear: fetcher.post.bind(fetcher, '/focus/-/near'),
  setFocusMode: fetcher.post.bind(fetcher, '/focus/-/set_mode'),
  setFocusToPoint: fetcher.post.bind(fetcher, '/focus/-/push_focus'),

  createRecorder: fetcher.post.bind(fetcher, '/recorders'),
  patchRecorder: fetcher.patch.bind(fetcher, '/recorders/:recorder_id'),
  deleteRecorder: fetcher.delete.bind(fetcher, '/recorders/:recorder_id'),
  startRecorder: fetcher.post.bind(fetcher, '/recorders/:recorder_id/-/start'),
  stopRecorder: fetcher.post.bind(fetcher, '/recorders/:recorder_id/-/stop'),
  startAllRecorders: fetcher.post.bind(fetcher, '/recorders/-/start_all'),
  stopAllRecorders: fetcher.post.bind(fetcher, '/recorders/-/stop_all'),

  deleteSpeakers: fetcher.delete.bind(fetcher, '/speakers'),
  deleteSpeaker: fetcher.delete.bind(fetcher, '/speakers/:speaker_id'),
  deleteInactiveSpeakers: fetcher.post.bind(fetcher, '/speakers/-/remove_inactive'),
  deleteAllSpeakers: fetcher.post.bind(fetcher, '/speakers/-/remove_all'),
  addSpeaker: fetcher.post.bind(fetcher, '/speakers/-/click_add'),
  ignoreSpeaker: fetcher.post.bind(fetcher, '/speakers/-/click_ignore'),

  patchPresets: fetcher.patch.bind(fetcher, '/presets'),
  createPreset: fetcher.post.bind(fetcher, '/presets/:preset_id/-/save_current_view'),
  setPresetName: fetcher.post.bind(fetcher, '/presets/:preset_id/_/set_name'),

  createTrackingZone: fetcher.post.bind(fetcher, '/zones'),
  patchTrackingZone: fetcher.patch.bind(fetcher, '/zones/:zone_id'),
  moveTrackingZoneEdge: fetcher.post.bind(fetcher, '/zones/:zone_id/-/adjust_edge'),
  deleteTrackingZone: fetcher.delete.bind(fetcher, '/zones/:zone_id'),
};

export default api;
