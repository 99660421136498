import useNotificationsStore from '@/stores/notifications';
import { isArray } from '@slideslive/fuse-kit/utils';

const doHandleResponse = (response) => {
  const notificationsStore = useNotificationsStore();
  const { addNotification } = notificationsStore;

  const messages = response.messages;
  const success = response.success || true;

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log(response);
  } else if (messages?.length) {
    // eslint-disable-next-line no-console
    console.log(messages);
  }

  if (messages?.length) {
    for (const notice of messages) {
      if (!notice.alert) continue;

      addNotification({ type: notice.level, title: notice.title, message: notice.text });
    }
  }

  return success;
};

const handleRequestResponse = (response) => {
  let success = true;

  if (isArray(response)) {
    for (const r of response) {
      if (doHandleResponse(r)) continue;

      success = false;
    }
  } else {
    success = doHandleResponse(response);
  }

  return success;
};

export default handleRequestResponse;
