<template>
  <TabPanel>
    <Text size="large">
      <Link :href="routerWanSettingsHref" scheme="underline" target="_blank">
        {{ $t('settings_tabs.router.router_wan_settings') }}
      </Link>
    </Text>
  </TabPanel>
</template>

<script setup>
// import buildNateUrl from '@/utils/build_nate_url';
import { Link, TabPanel, Text } from '@slideslive/fuse-kit/vue';
import { computed } from 'vue';

// const routerWanSettingsHref = computed(() => buildNateUrl('/router_wan')); # Hotfix address to old NATE UI
const routerWanSettingsHref = computed(() => `//${window.location.hostname}:5001/router`);

</script>
