import useDataStore from '@/stores/data';
import useNotificationsStore from '@/stores/notifications';
import handleError from '@/utils/handle_request_error';
import handleResponse from '@/utils/handle_request_response';
import { generateRandomId, isArray } from '@slideslive/fuse-kit/utils';
import { storeToRefs } from 'pinia';
import { computed, readonly, ref, unref, watch } from 'vue';

/**
 * Composable options
 * @typedef {Object} Options
 * @property {boolean} [disabled=false] Whether sending requests is disabled or not.
 * @property {boolean} [withoutData=false] Whether to send the API request without date or not.
 * For example for DELETE or POST requests without bodies.
 * @property {boolean} [doNotAbortWhenLoading=false] Whether to abort previous request or not.
 * @property {Function} [successCallback=null] Callback to call when request is successful and next polling request
 * is finished.
 * @property {Function} [errorCallback=null] Callback to call when request fails.
 */

/**
 * Composable used for performing API requests
 * Provides loading state, abort functionality and request callbacks.
 * `loading` is set to false when request is finished and next polling request is finished as well.
 *
 * @param {Function} queryFn - Function that is triggered to make the request.
 * @param {Options=} options - Composable options
 * @return {{ loading, trigger }} - Reactive loading state and trigger function.
 */
function useLoadingUntilRequested(
  queryFn,
  {
    disabled = false,
    withoutData = false,
    doNotAbortWhenLoading = false,
    successCallback = null,
    errorCallback = null,
  } = {},
) {
  const dataStore = useDataStore();
  const { setLoadingRequest } = dataStore;
  const { loadingRequests } = storeToRefs(dataStore);
  const notificationsStore = useNotificationsStore();
  const { closeNotification } = notificationsStore;

  const queryId = generateRandomId(20);
  const fetching = ref(false);
  const loading = computed(() => fetching.value || loadingRequests.value.includes(queryId));
  let currentRequestId = null;
  let abortController = new AbortController();

  /**
   * Function to trigger the queryFn
   *
   * @param {*} data - Data to be sent to the server and optionaly draftState.
   * @param {{ key?, [key]? }} options - Options to be passed to the queryFn, usually contain 'key' and '[key]' for
   * replacing item ID ({ key: 'speaker_id', speakerId: 123 }).
   */
  const trigger = async (...args) => {
    if (unref(disabled)) return;

    if (unref(fetching) && !unref(doNotAbortWhenLoading)) {
      abortController.abort();
    }

    if (abortController.signal.aborted) {
      abortController = new AbortController();
    }

    fetching.value = true;

    const requestId = generateRandomId(20);
    currentRequestId = requestId;

    let data;
    let params;

    if (withoutData) {
      [params] = args;
    } else {
      [data, params] = args;
    }

    try {
      let response;

      closeNotification(queryId);

      if (withoutData) {
        response = await unref(queryFn)(null, { abortController, ...params });
      } else {
        response = await unref(queryFn)(data, { abortController, ...params });
      }

      if (isArray(response)) {
        response = response.filter((r) => r);
      }

      if (!response || (isArray(response) && !response.length)) return;

      const success = handleResponse(response);

      if (currentRequestId === requestId) {
        if (success) {
          setLoadingRequest(queryId);

          if (successCallback) {
            const unwatch = watch(loading, (loadingValue) => {
              if (loadingValue) return;

              unwatch();
              successCallback(response);
            });
          }
        } else if (errorCallback) {
          errorCallback(response, null);
        }
      }
    } catch (error) {
      handleError(error, params, data, queryId);

      if (currentRequestId === requestId && errorCallback) {
        errorCallback(null, error);
      }
    } finally {
      if (currentRequestId === requestId) {
        fetching.value = false;
      }
    }
  };

  return {
    loading: readonly(loading),
    trigger,
  };
}

export default useLoadingUntilRequested;
