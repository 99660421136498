<template>
  <template v-if="item.href">
    <Button
      v-if="dropdown"
      v-tooltip="tooltip"
      tag="a"
      :href="item.href"
      target="_blank"
      color="transparentWhite"
      size="small"
      class="tw-w-full tw-text-left"
      block
    >
      {{ item.label }}
    </Button>

    <Link v-else v-tooltip="tooltip" :href="item.href" target="_blank" scheme="underline"> {{ item.label }} </Link>
  </template>

  <Dropdown
    v-else-if="item.items"
    v-tooltip="tooltip"
    :overlay="false"
    :class="dropdown ? 'tw-w-full' : ''"
    :trigger-attrs="
      dropdown
        ? { reset: true, color: 'transparentWhite', size: 'small', block: true, class: 'tw-w-full tw-text-left' }
        : { linkButton: true, reset: true, scheme: 'underline' }
    "
  >
    <template #trigger>{{ item.label }}</template>

    <template #menu>
      <Button
        v-for="item in item.items"
        :key="item.id"
        tag="a"
        :href="item.href"
        target="_blank"
        color="transparentWhite"
        size="small"
        class="tw-text-left"
        block
      >
        {{ item.label }}
      </Button>
    </template>
  </Dropdown>

  <template v-else-if="item.onClick">
    <Button
      v-if="dropdown"
      v-tooltip="tooltip"
      color="transparentWhite"
      size="small"
      class="tw-w-full tw-text-left"
      block
      @click="item.onClick"
    >
      {{ item.label }}
    </Button>

    <Link v-else v-tooltip="tooltip" tag="button" scheme="underline" class="tw-text-left" @click="item.onClick">
      {{ item.label }}
    </Link>
  </template>
</template>

<script setup>
import { Button, Dropdown, Link } from '@slideslive/fuse-kit/vue';
import { computed } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  dropdown: {
    type: Boolean,
    default: false,
  },
});

const tooltip = computed(() => props.item.tooltip || '');
</script>
