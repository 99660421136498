<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.other.title') }}</Heading>

    <div class="tw-flex tw-flex-wrap tw-items-start tw-gap-5">
      <div class="tw-flex tw-flex-wrap tw-items-start tw-gap-2">
        <Button
          color="semiTransparentWhite"
          tag="a"
          :href="mainCamSettingsHref"
          target="_blank"
          download
          class="tw-text-left"
        >
          <Icon name="download" class="tw-mr-2 tw-size-6 tw-fill-current" />
          {{ $t('settings_modal.other.download_main_cam_settings') }}
        </Button>

        <Button
          color="semiTransparentWhite"
          :disabled="locked || disabled"
          :loading="resetCamPlatformInProgress"
          @click="resetCamPlatform"
        >
          {{ $t('settings_modal.other.pan_tilt_recalibration') }}

          <div
            v-if="resetCamPlatformSuccess && !resetCamPlatformInProgress"
            class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-lg tw-backdrop-blur-sm tw-backdrop-filter"
          >
            <Icon name="check" class="tw-size-6 tw-fill-state-succ" />
          </div>

          <div
            v-if="resetCamPlatformError && !resetCamPlatformInProgress"
            class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-lg tw-backdrop-blur-sm tw-backdrop-filter"
          >
            <Icon name="close" class="tw-size-6 tw-fill-state-err" />
          </div>
        </Button>
      </div>

      <FormField
        ref="mainCanSrtStream"
        :value="app.main_cam_srt_stream_path"
        readonly
        :label="$t('settings_modal.other.srt_stream_from_main_camera.label')"
        :right-tag-attrs="{ class: 'tw-pr-2' }"
        class="tw-w-full sm:tw-w-[calc(100%/2-0.625rem)] md:tw-w-[calc(100%/3-0.625rem)] lg:tw-w-[calc(100%/4-0.625rem)]"
      >
        <template #right-tag>
          <CopyToClipboard :target="mainCanSrtStreamRef" size="small" type="circular" color="transparentWhite" />
        </template>
      </FormField>
    </div>
  </div>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import buildNateUrl from '@/utils/build_nate_url';
import { Button, CopyToClipboard, FormField, Heading, Icon } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, useTemplateRef, watch } from 'vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['actionInProgress']);

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { app } = storeToRefs(dataStore);

const mainCanSrtStreamRef = useTemplateRef('mainCanSrtStream');

const resetCamPlatformSuccess = ref(false);
const resetCamPlatformError = ref(false);

const mainCamSettingsHref = computed(() => buildNateUrl('/files/camera_config'));

const showResetCamPlatformResult = (success) => {
  resetCamPlatformSuccess.value = success;
  resetCamPlatformError.value = !success;

  setTimeout(() => {
    resetCamPlatformSuccess.value = false;
    resetCamPlatformError.value = false;
  }, 3000);
};

const { loading: resetCamPlatformInProgress, trigger: resetCamPlatform } = useLoadingRequest(api.resetCamPlatform, {
  successCallback: () => {
    showResetCamPlatformResult(true);
  },
  errorCallback: () => {
    showResetCamPlatformResult(false);
  },
});

watch(resetCamPlatformInProgress, (inProgress) => {
  emit('actionInProgress', inProgress);
});
</script>
