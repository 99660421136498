<template>
  <TabPanel v-bind="$attrs" class="tw-grid tw-grid-cols-1 tw-gap-x-10 tw-gap-y-5 sm:tw-grid-cols-2">
    <SettingsRange
      v-tooltip="resetProcessPanTiltSpeedTooltip"
      v-model.number="resetProcessPanTiltSpeed"
      :disabled="locked"
      :min="movement.manual_move_pan_tilt_speed.min"
      :max="movement.manual_move_pan_tilt_speed.max"
      :step="movement.manual_move_pan_tilt_speed.step"
      :label="$t('settings_tabs.advanced.reset_process_pan_tilt_speed.label')"
      @update:model-value="throttledResetProcessPanTiltSpeedPatch"
    />

    <SettingsRange
      v-tooltip="resetProcessZoomSpeedTooltip"
      v-model.number="resetProcessZoomSpeed"
      :disabled="locked"
      :min="movement.manual_move_zoom_speed.min"
      :max="movement.manual_move_zoom_speed.max"
      :step="movement.manual_move_zoom_speed.step"
      :label="$t('settings_tabs.advanced.reset_process_zoom_speed.label')"
      @update:model-value="throttledResetProcessZoomSpeedPatch"
    />

    <Checkbox
      :model-value="controller.auto_panel_discussion"
      :disabled="locked"
      type="switch"
      :right-label="$t('settings_tabs.advanced.auto_panel_discussion.label')"
      @update:model-value="setAutoPanelDiscussion({ controller: { auto_panel_discussion: $event } })"
    />

    <Text size="large" class="tw-text-right">
      <Link tag="button" scheme="underline" v-tooltip="$t('settings_tabs.advanced.other.tooltip')" @click="showOther">
        {{ $t('settings_tabs.advanced.other.label') }}
      </Link>
    </Text>
  </TabPanel>

  <AdvancedOtherModal v-model="otherOpen" />
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingRequest from '@/composables/loading_request';
import useSliderValue from '@/composables/slider_value';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Checkbox, Link, TabPanel, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import AdvancedOtherModal from './AdvancedOtherModal.vue';
import SettingsRange from './SettingsRange.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked, tooltips } = storeToRefs(appStore);
const dataStore = useDataStore();
const { movement, controller } = storeToRefs(dataStore);

const otherOpen = ref(false);
const resetProcessPanTiltSpeed = ref(movement.value.manual_move_pan_tilt_speed.value);
const resetProcessZoomSpeed = ref(movement.value.manual_move_zoom_speed.value);

const resetProcessPanTiltSpeedTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('settings_tabs.advanced.reset_process_pan_tilt_speed.tooltip'),
}));
const resetProcessZoomSpeedTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('settings_tabs.advanced.reset_process_zoom_speed.tooltip'),
}));

const { trigger: patchState } = useLoadingRequest(api.patchState);
const { trigger: setAutoPanelDiscussion } = useDraftState(api.patchState, {
  statePathToClear: ['controller', 'auto_panel_discussion'],
});

const { throttledQueryFn: throttledResetProcessPanTiltSpeedPatch } = useSliderValue({
  queryFn: () => patchState({ movement: { manual_move_pan_tilt_speed: { value: resetProcessPanTiltSpeed.value } } }),
  resetToStateFn: () => {
    resetProcessPanTiltSpeed.value = movement.value.manual_move_pan_tilt_speed.value;
  },
  stateValueToWatch: () => movement.value.manual_move_pan_tilt_speed.value,
});

const { throttledQueryFn: throttledResetProcessZoomSpeedPatch } = useSliderValue({
  queryFn: () => patchState({ movement: { manual_move_zoom_speed: { value: resetProcessZoomSpeed.value } } }),
  resetToStateFn: () => {
    resetProcessZoomSpeed.value = movement.value.manual_move_zoom_speed.value;
  },
  stateValueToWatch: () => movement.value.manual_move_zoom_speed.value,
});

const showOther = () => {
  otherOpen.value = true;
};
</script>
