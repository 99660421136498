<template>
  <Notifications :style="contentStyle" />

  <div v-if="remoteStateDisabled">Remote state disabled</div>

  <div v-if="isPending || isLoading" class="tw-flex tw-h-full tw-items-center tw-justify-center">
    <Spinner size="large" />
  </div>

  <div v-else class="tw-relative tw-flex tw-min-h-max tw-flex-col tw-px-2 md:tw-px-5" :style="contentStyle">
    <div
      v-if="remoteStateUnresponsive"
      class="tw-absolute tw-inset-0 tw-z-[200] tw-flex tw-items-center tw-justify-center tw-bg-dark/68 tw-backdrop-blur"
    >
      <Spinner size="large" />
    </div>

    <Header ref="header" class="tw-mx-auto tw-w-full tw-max-w-[1452px]" />

    <main
      ref="main"
      class="tw-flex tw-w-full tw-max-w-192 tw-flex-1 tw-flex-col tw-items-start xl:tw-mx-auto xl:tw-max-w-[1452px]"
    >
      <MiddleSection class="xl:tw-h-[276px]" />
      <BottomSection class="xl:tw-max-h-[calc(100vh-5rem-276px)] xl:tw-min-h-92" />
    </main>
  </div>

  <div id="teleport-container" :style="contentStyle" />
</template>

<script setup>
import api from '@/api';
import Header from '@/components/Header.vue';
import Notifications from '@/components/Notifications.vue';
import usePolling from '@/composables/polling';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import useNotificationsStore from '@/stores/notifications';
import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import { Spinner } from '@slideslive/fuse-kit/vue';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, useTemplateRef, watch } from 'vue';

import BottomSection from './components/BottomSection.vue';
import MiddleSection from './components/MiddleSection.vue';
import { DEFAULT_RETRY_COUNT } from './constants';

const appStore = useAppStore();
const { setViewZoom } = appStore;
const { viewZoom } = storeToRefs(appStore);
const dataStore = useDataStore();
const { setIsLoading, setIsFetching, setIsError, setError, updateRemoteState, setSessionId } = dataStore;
const { sessionId, remoteStateDisabled, remoteStateUnresponsive } = storeToRefs(dataStore);
const notificationsStore = useNotificationsStore();
const { addNotification, closeNotification } = notificationsStore;

const headerComponent = useTemplateRef('header');
const headerRef = computed(() => headerComponent.value?.el);
const mainRef = useTemplateRef('main');
const appRef = computed(() => headerRef.value?.parentElement?.parentElement);

const { height: windowHeight, width: windowWidth } = useWindowSize();

const remoteStatePollingEnabled = computed(
  () => isVariableDefinedNotNull(sessionId.value) && !remoteStateDisabled.value,
);

const contentStyle = computed(() => {
  if (viewZoom.value <= 1) return null;

  return { zoom: viewZoom.value };
});

const recalculateContentStyle = () => {
  if (!appRef.value || !headerRef.value || !mainRef.value) return;

  setViewZoom(1);

  // 1452 is the max width of the content (see template above), 20 is the padding on each side for this resolution
  if (windowWidth.value <= 1452 + 2 * 20) return;

  const contentHeight = Math.round(headerRef.value.offsetHeight + mainRef.value.offsetHeight);

  if (windowHeight.value <= contentHeight) return;

  const contentWidth = Math.max(headerRef.value.offsetWidth, mainRef.value.offsetWidth) + 2 * 20;
  let newZoom = windowWidth.value / contentWidth;

  if (contentHeight * newZoom > windowHeight.value) {
    newZoom = windowHeight.value / contentHeight;
  }

  setViewZoom(newZoom);
};

const openSession = async (retry = 1) => {
  if (retry === 1) {
    closeNotification('openSession');
  }

  if (retry > DEFAULT_RETRY_COUNT) {
    setIsError(true);
    addNotification({
      id: 'openSession',
      type: 'error',
      message: 'Failed to open the NATE session. \nIf the issue doesn\'t resolve itself within a few seconds, refresh the page.',
      closable: false,
    });

    return;
  }

  try {
    const { success, session_id: newSessionId } = await api.open();

    if (success) {
      setSessionId(newSessionId);
    } else {
      setTimeout(() => {
        openSession(retry + 1);
      }, retry * 250);
    }
  } catch (err) {
    setTimeout(() => {
      openSession(retry + 1);
    }, retry * 250);
  }
};

const {
  isPending,
  isLoading,
  isFetching,
  isError: pollingIsError,
  error: pollingError,
  data: remoteState,
} = usePolling({
  queryFn: api.state,
  enabled: remoteStatePollingEnabled,
  pollingInterval: 1000,
});

watch([isPending, isLoading], () => setIsLoading(isPending.value || isLoading.value));
watch(isFetching, setIsFetching);
watch(pollingIsError, (value) => {
  setIsError(value);

  if (value) {
    addNotification({
      id: 'pollingError',
      type: 'error',
      message: 'Failed to fetch the NATE remote state. \nIf the issue doesn\'t resolve itself within a few seconds, refresh the page.',
      closable: false,
    });
  } else {
    closeNotification('pollingError');
  }
});
watch(pollingError, setError);
watch(remoteState, updateRemoteState);
watch([appRef, headerRef, mainRef, windowHeight, windowWidth], recalculateContentStyle);

onMounted(() => {
  openSession();
});
</script>
