<template>
  <ControlSection>
    <Checkbox
      v-model="autoCut"
      type="switch"
      :disabled="locked"
      :right-label="$t('auto_cut.label')"
      :message="autoCutMessage"
      @update:model-value="setAutoCut({ auto_cut: $event })"
    />

    <ValueSwitcher
      v-model.number="selectedCameraIndex"
      :disabled="locked || switcher.auto_cut"
      :options="cameraOptions"
      @update:model-value="setCamera({ camera_id: $event })"
    />
  </ControlSection>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Checkbox, ValueSwitcher } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { switcher } = storeToRefs(dataStore);

const autoCut = ref(switcher.value.auto_cut);
const selectedCameraIndex = ref(switcher.value.selected_camera.value);

const autoCutMessage = computed(() =>  {


  if (switcher.value.auto_cut && switcher.value.connected) {
    return t('auto_cut.auto_switching_message');
  }

  if (switcher.value.connected) {
  return t('auto_cut.manual_switching_message');
  }

  return t('auto_cut.not_connected_message');

})

const cameraOptions = computed(() =>
  switcher.value.selected_camera.options
    .map((number) => {
      const label = number === 0 ? t('camera_switch.wideshot') : t('camera_switch.cam', { number });

      return {
        value: number,
        label,
      };
    })
    // Make the Wideshot last
    .sort((a, b) => {
      if (a.value === 0) return 1;
      if (b.value === 0) return -1;

      return a.value - b.value;
    }),
);

const { trigger: setAutoCut } = useDraftState(api.setAutoCut, {
  statePath: ['switcher', 'auto_cut'],
  draftStateValue: autoCut,
});

const { trigger: setCamera } = useDraftState(api.setCamera, {
  statePath: ['switcher', 'selected_camera', 'value'],
  draftStateValue: selectedCameraIndex,
});

watch([() => switcher.value.connected, () => switcher.value.auto_cut], () => {
  autoCut.value = switcher.value.connected && switcher.value.auto_cut;
});

watch(
  () => switcher.value.selected_camera.value,
  (value) => {
    selectedCameraIndex.value = value;
  },
);
</script>
