<template>
  <Teleport to="#teleport-container">
    <Modal ref="modal" v-model="show" @opening="reset" @close="reset">
      <ModalContent size="large">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('advanced_other_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button :disabled="saving" color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
          <Button :loading="locked || saving" type="submit" form="advanced-other-form">{{ $t('save') }}</Button>
        </template>

        <Form
          id="advanced-other-form"
          class="tw-grid tw-grid-cols-1 tw-gap-x-10 tw-gap-y-5 sm:tw-grid-cols-2"
          @submit="triggerSave"
        >
          <div class="tw-flex-1 tw-space-y-5">
            <SettingsRange
              v-model.number="captureDelay"
              :disabled="locked"
              :min="cameras.main.capture_delay.min"
              :max="cameras.main.capture_delay.max"
              :step="cameras.main.capture_delay.step"
              :label="$t('advanced_other_modal.capture_delay_ms.label')"
            />

            <FormField
              v-model="movementMethod"
              type="select"
              :label="$t('advanced_other_modal.movement_method.label')"
              class="tw-flex-1"
            >
              <option v-for="value in movementMethodOptions" :key="value" :value="value">
                {{ value }}
              </option>
            </FormField>

            <div class="tw-flex tw-flex-wrap tw-gap-2">
              <Button
                color="semiTransparentWhite"
                block
                tag="a"
                :href="dataFromCurrentProcessHref"
                target="_blank"
                download
                class="tw-text-left"
              >
                <Icon name="download" class="tw-mr-2 tw-size-6 tw-fill-current" />
                {{ $t('advanced_other_modal.download_data_from_current_process') }}
              </Button>

              <Button
                color="semiTransparentWhite"
                block
                tag="a"
                :href="dataFromAllProcessesHref"
                target="_blank"
                download
                class="tw-text-left"
              >
                <Icon name="download" class="tw-mr-2 tw-size-6 tw-fill-current" />
                {{ $t('advanced_other_modal.download_data_from_all_processes') }}
              </Button>
            </div>
          </div>

          <div class="tw-space-y-5">
            <div class="tw-flex tw-flex-wrap tw-gap-x-5 tw-gap-y-2 sm:tw-flex-col">
              <Checkbox
                v-model="showDebug"
                :disabled="locked"
                :right-label="$t('advanced_other_modal.show_debug.label')"
              />

              <Checkbox
                v-model="showCalibrationGrid"
                :disabled="locked"
                :right-label="$t('advanced_other_modal.show_calibration_grid.label')"
              />
            </div>

            <div class="tw-flex tw-flex-1 tw-items-end tw-justify-between tw-gap-5">
              <div class="tw-flex tw-flex-wrap tw-gap-5 sm:tw-flex-col">
                <Text size="large">
                  <Link scheme="underline" :href="cameraLogUrl('error')" target="_blank" download>
                    {{ $t('advanced_other_modal.camera_error_log') }}
                  </Link>
                </Text>

                <Text size="large">
                  <Link scheme="underline" href="/state" target="_blank" download>
                    {{ $t('advanced_other_modal.conf_data_dev') }}
                  </Link>
                </Text>

                <Text size="large">
                  <Link scheme="underline" :href="cameraLogUrl('system')" target="_blank" download>
                    {{ $t('advanced_other_modal.camera_system_log') }}
                  </Link>
                </Text>
              </div>
            </div>
          </div>
        </Form>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingUntilRequested from '@/composables/loading_until_requested';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import buildNateUrl from '@/utils/build_nate_url';
import setIfChanged from '@/utils/set_if_changed';
import {
  Button,
  Checkbox,
  Form,
  FormField,
  Heading,
  Icon,
  Link,
  Modal,
  ModalContent,
  Text,
} from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, useTemplateRef, watch } from 'vue';

import SettingsRange from './SettingsRange.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { cameras, controller, app, dev } = storeToRefs(dataStore);

const show = defineModel();
const modalRef = useTemplateRef('modal');

const showDebug = ref(app.value.debug_mode);
const showCalibrationGrid = ref(controller.value.show_calibration_grid);
const captureDelay = ref(cameras.value.main.capture_delay.value);

const currentMovementMethod = computed(() => dev.value?.config?.movement_method?.value || 'NONE');
const movementMethodOptions = computed(() => dev.value?.config?.movement_method?.options || ['NONE']);
const movementMethod = ref(currentMovementMethod.value);

const dataFromCurrentProcessHref = computed(() => buildNateUrl('/files/process_data/current'));

const dataFromAllProcessesHref = computed(() => buildNateUrl('/files/process_data/all_zip'));

const cameraLogUrl = (kind) => `${app.value.main_cam_url}/-wvdata-/FileData?filetype=log&kind=${kind}`;

const { trigger: patchStateApp } = useDraftState(api.patchState, { statePathToClear: ['app', 'debug_mode'] });
const { trigger: patchStateController } = useDraftState(api.patchState, {
  statePathToClear: ['controller', 'show_calibration_grid'],
});
const { trigger: patchStateCamerasMain } = useDraftState(api.patchState, { statePathToClear: ['cameras', 'main'] });
const { trigger: patchDevConfigMovementMethod } = useDraftState(api.patchDev, {
  statePath: ['dev', 'config', 'movement_method', 'value'],
  draftStateValue: movementMethod,
});

const save = async () => {
  if (locked.value) return Promise.resolve();

  const stateAppPatch = {};
  const stateControllerPatch = {};
  const stateCamerasMainPatch = {};
  const devConfigMovementMethodPatch = {};

  setIfChanged(stateAppPatch, 'debug_mode', app.value.debug_mode, showDebug);

  setIfChanged(
    stateControllerPatch,
    'show_calibration_grid',
    controller.value.show_calibration_grid,
    showCalibrationGrid,
  );

  setIfChanged(stateCamerasMainPatch, 'capture_delay', cameras.value.main.capture_delay.value, {
    value: captureDelay.value,
  });

  setIfChanged(
    devConfigMovementMethodPatch,
    'movement_method',
    currentMovementMethod.value,
    movementMethod.value,
  );

  const promises = [];

  if (Object.keys(stateAppPatch).length) {
    promises.push(patchStateApp({ app: stateAppPatch }));
  }

  if (Object.keys(stateControllerPatch).length) {
    promises.push(patchStateController({ controller: stateControllerPatch }));
  }

  if (Object.keys(stateCamerasMainPatch).length) {
    promises.push(patchStateCamerasMain({ cameras: { main: stateCamerasMainPatch } }));
  }

  if (Object.keys(devConfigMovementMethodPatch).length) {
    promises.push(patchDevConfigMovementMethod(devConfigMovementMethodPatch));
  }

  if (promises.length === 0) return Promise.resolve();

  return Promise.all(promises);
};

const { loading: saving, trigger: triggerSave } = useLoadingUntilRequested(save);

const reset = () => {
  showDebug.value = app.value.debug_mode;
  showCalibrationGrid.value = controller.value.show_calibration_grid;
  captureDelay.value = cameras.value.main.capture_delay.value;
  movementMethod.value = currentMovementMethod.value;
};

watch(saving, (newSaving, oldSaving) => {
  if (oldSaving && !newSaving) {
    modalRef.value.accept();
  }
});
</script>
